import { httpService } from "@/dependencies";
import { AIRDROP_QUERIES } from "@/modules/airdrop";
import { useServerState } from "@/modules/core/react-query/hooks";
import { useGlobalState } from "@/modules/shared/context";
import { useMetaSoccerContract } from "@/modules/shared/hooks";
import groupBy from "@/modules/shared/utils/groupBy";
import { ContractName, FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

const AIRDROP_START_DATE = new Date(2024, 5, 20, 15, 0, 0);

const useAirdropState = () => {
	const isAirdropGameplayEnabled = useFlag(FeatureFlag.PlayToAirdropGameplay);
	const { address } = useGlobalState();

	const [isGetPassEnabled, setIsGetPassEnabled] = useState(true);
	const [isClaimingPass, setIsClaimingPass] = useState(false);

	const { data: airdropRanking = { items: [], lastUpdated: new Date() }, isLoading: isLoadingRanking } =
		useServerState(AIRDROP_QUERIES.getRanking(), { enabled: Boolean(address) });

	const { data: airdropTasks = [], isLoading: isLoadingTasks } = useServerState(AIRDROP_QUERIES.getTasks(), {
		enabled: Boolean(address)
	});

	const { data: availableBoxes = 0, isLoading: isLoadingAvailableBoxes } = useServerState(
		AIRDROP_QUERIES.getAvailableBoxes()
	);

	const { data: starterBox = false, isLoading: isLoadingStarterBox } = useServerState(
		AIRDROP_QUERIES.getStarterBox(address!),
		{
			enabled: Boolean(address)
		}
	);

	const { contract: starterBoxContract } = useMetaSoccerContract(ContractName.STARTER_BOX);
	const { contract: starterBoxRedeemerContract } = useMetaSoccerContract(ContractName.STARTER_BOX_REDEEMER);

	const { data: hasPass = false, isLoading: isLoadingPass } = useServerState(AIRDROP_QUERIES.getPass(address!), {
		enabled: Boolean(address) && isGetPassEnabled,
		refetchInterval: 1000
	});

	const {
		data: starterBoxClaim = false,
		isLoading: isLoadingStarterBoxClaim,
		refetch: refetchStarterBox
	} = useServerState(AIRDROP_QUERIES.getStarterBoxClaim(address!), {
		enabled: Boolean(address)
	});

	const claimPass = useCallback(async (code: string) => {
		setIsClaimingPass(true);
		httpService
			.post("/airdrop/claimPass", { code })
			.then(() => {})
			.catch(() => setIsClaimingPass(false));
	}, []);

	const claimStarterBox = useCallback(async () => {
		if (!address) return;
		if (!starterBoxContract) return;
		if (!starterBoxRedeemerContract) return;

		if (!starterBox) {
			await starterBoxRedeemerContract.call("claim", [], { gasLimit: 42000000 });
		}

		const tokenId = await starterBoxContract.call("tokenOfOwnerByIndex", [address, 0]);
		await starterBoxContract.call("unwrap", [tokenId, address], { gasLimit: 42000000 });

		await refetchStarterBox();
	}, [address, starterBox, starterBoxContract, starterBoxRedeemerContract, refetchStarterBox]);

	useEffect(() => {
		if (hasPass) setIsGetPassEnabled(false);
	}, [hasPass]);

	const { data: suspiciousAddress } = useServerState<{ address: string; urlCases: string[]; suspicious: boolean }>(
		AIRDROP_QUERIES.getSuspiciousAddresses(address!),
		{
			enabled: Boolean(address)
		}
	);

	return {
		airdropRanking,
		airdropStartDate: AIRDROP_START_DATE,
		airdropTasks: groupBy(airdropTasks, (task) => task.key),
		availableBoxes,
		hasAirdropStarted: isAirdropGameplayEnabled && hasPass,
		hasPass,
		hasStarterBox: starterBoxClaim,
		hasOpenedStarterBox: starterBoxClaim && !starterBox,
		isClaimingPass: isClaimingPass && !hasPass,
		suspiciousAddress,
		isLoading:
			isLoadingAvailableBoxes ||
			isLoadingPass ||
			isLoadingRanking ||
			isLoadingStarterBox ||
			isLoadingStarterBoxClaim ||
			isLoadingTasks,
		claimPass,
		claimStarterBox
	};
};

const AirdropContext = createContext<ReturnType<typeof useAirdropState> | undefined>(undefined);

type AirdropContextProviderProps = { children: ReactNode };

const AirdropContextProvider = ({ children }: AirdropContextProviderProps) => {
	const airdropState = useAirdropState();

	return <AirdropContext.Provider value={airdropState}>{children}</AirdropContext.Provider>;
};

export { AirdropContext, AirdropContextProvider };

export const useAirdrop = () => {
	const context = useContext(AirdropContext);
	if (context === undefined) {
		throw new Error("useAirdrop must be used within a AirdropContextProvider");
	}
	return context;
};
