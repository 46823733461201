import { getDomain } from "./helpers";

export const getUnleashProxyUrl = () => {
	const domain = getDomain();

	if (domain === "localhost") {
		return "https://manag3r.devsoccer.com/unleash/proxy";
	}

	return `https://manag3r.${domain}/unleash/proxy`;
};
