import { useAirdrop } from "@/modules/home/hooks/useAirdrop";
import { Alert, Paragraph } from "@/modules/shared/components";
import { useGlobalState } from "@/modules/shared/context";
import { getOrdinalSuffix } from "@/modules/shared/utils/getOrdinalSuffix";
import { Col, Row, Typography, useTheme } from "@metasoccer/metasoccer-ds";
import { useVirtualizer } from "@tanstack/react-virtual";
import { formatDate } from "date-fns";
import { memo, useRef } from "react";
import { CssListWrapper, CssStandingsWrapper, HeaderStyled } from "./AirdropRanking.styles";
import { AirdropRankingItem } from "./AirdropRankingItem";

export const AirdropRanking = memo(() => {
	const parentRef = useRef(null);

	const theme = useTheme();

	const { airdropRanking, suspiciousAddress } = useAirdrop();
	const { selectedTeam } = useGlobalState();

	const selectedItemRankingItem = airdropRanking.items.find((item) => item.team.id === selectedTeam?.id);

	const rowVirtualizer = useVirtualizer({
		count: airdropRanking.items.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 48,
		overscan: 10
	});

	const isSuspiciousAddress = suspiciousAddress?.suspicious;

	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Col style={{ flexGrow: 1, height: 0, overflow: "hidden" }}>
				<HeaderStyled>
					<Col gap={8}>
						<Typography variant="h5">Leaderboard</Typography>
						<Col>
							<Typography variant="caption" light>
								Last Updated: {formatDate(airdropRanking.lastUpdated, "Pp")}
							</Typography>
							<Typography variant="caption" light>
								<a
									href="https://docs.metasoccer.com/Important-information-about-the-P2A-rules-cf741e6cbed1466ca622faa88c547a65"
									target="_blank">
									Rules & Fair Play Policy
								</a>
							</Typography>
						</Col>
						<Row style={{ maxWidth: "409px" }}>
							<Col gap={4}>
								{isSuspiciousAddress && (
									<Alert variant="error">
										<Typography variant="body2">
											<Paragraph variant="body2" textAlign="left">
												Our team has identified activity associated with your address that we
												believe may be suspicious. Please review the case using the link
												provided. If you believe this is an error, you may submit an appeal and
												provide evidence to the contrary. You have until September 1st at 23:59
												UTC to respond.
											</Paragraph>
											<Paragraph variant="body2"> </Paragraph>

											{suspiciousAddress?.urlCases?.map((url, index) => (
												<Paragraph variant="body2" textAlign="left">
													<a key={index} href={url} target="_blank" rel="noopener noreferrer">
														Review your case here
													</a>
												</Paragraph>
											))}

											<Paragraph variant="body2"> </Paragraph>
											<Paragraph variant="body2" textAlign="left">
												To appeal any decision regarding your cases, please head over to{" "}
												<a
													href={
														"https://discord.com/channels/953005818394050570/1243232641478688888"
													}
													target="_blank"
													rel="noopener noreferrer">
													Discord
												</a>
												, open a ticket, and present your evidence.
											</Paragraph>
										</Typography>
									</Alert>
								)}
								{!isSuspiciousAddress && (
									<Alert variant="info">
										<Typography variant="body2" textAlign="left">
											Our team is currently reviewing the rankings and conducting a comprehensive
											check of our Rules & Fair Play Policy. Stay tuned for updates!
										</Typography>
									</Alert>
								)}
							</Col>
						</Row>
						<Row>
							{selectedItemRankingItem && (
								<Typography variant="body2Bold" style={{ fontSize: 18, fontWeight: "bold" }}>
									{getOrdinalSuffix(selectedItemRankingItem.position)}
								</Typography>
							)}
						</Row>
					</Col>
				</HeaderStyled>
				<Col style={{ flexGrow: 1, height: 0, overflow: "auto" }}>
					<CssStandingsWrapper>
						<CssListWrapper ref={parentRef} style={{ overflow: "auto" }}>
							<div
								style={{
									height: `${rowVirtualizer.getTotalSize()}px`,
									width: "100%",
									position: "relative"
								}}>
								{rowVirtualizer.getVirtualItems().map((virtualRow) => (
									<div
										key={virtualRow.index}
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "100%",
											transform: `translateY(${virtualRow.start}px)`
										}}>
										<AirdropRankingItem
											team={airdropRanking.items[virtualRow.index].team}
											position={virtualRow.index + 1}
											points={airdropRanking.items[virtualRow.index].value}
										/>
									</div>
								))}
							</div>
						</CssListWrapper>
					</CssStandingsWrapper>
				</Col>
			</Col>
		</div>
	);
});
