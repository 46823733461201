import { useIsMobile } from "@/layouts/context";
import { useServerState } from "@/modules/core/react-query/hooks";
import { Container, TapbarsLayout } from "@/modules/shared/components";
import { getImgUrl } from "@/modules/shared/utils";
import { Col, Row, Typography } from "@metasoccer/metasoccer-ds";
import { FeatureFlag } from "@metasoccer/metasoccer-types";
import { useFlag } from "@unleash/proxy-client-react";
import { memo } from "react";
import { BuyMSU, Product } from "../../components";
import { BuyCatalog } from "../../components/BuyCatalog";
import { SHOP_QUERIES } from "../../queries";

enum TabOptions {
	SHOP = "shop"
}

export const ShopPage = memo(() => {
	const isAirdropGameplayEnabled = useFlag(FeatureFlag.PlayToAirdropGameplay);
	const isBuyMSUUsingStripeEnabled = useFlag(FeatureFlag.BuyMSUUsingStripe);

	const { data: catalog } = useServerState(SHOP_QUERIES.getActiveCatalogue());

	const isMobile = useIsMobile();

	const gridProps = {
		gridTemplateColumns: `repeat(${isMobile ? 2 : 5}, 1fr)`,
		gap: isMobile ? 12 : 24
	};

	const openURLInNewTab = (url: string) => {
		window.open(url, "_blank");
	};

	return (
		<TapbarsLayout
			tabsSize="lg"
			tabs={[
				{
					key: TabOptions.SHOP,
					title: "Shop",
					render: () => (
						<Container paddingY={0}>
							<Col gap={24} style={{ paddingBottom: isMobile ? 12 : 24 }}>
								<div
									style={{
										display: "grid",
										overflow: "hidden",
										padding: 1,
										width: "100%",
										...gridProps
									}}>
									{isBuyMSUUsingStripeEnabled && <BuyMSU />}
									{!isAirdropGameplayEnabled && (
										<Product
											backgroundColor={
												"linear-gradient(0deg, rgba(61, 114, 253, 0.3), rgba(61, 114, 253, 0.3)), #06060A;"
											}
											callToAction={
												<Row gap={8} alignItems="center">
													<img
														alt="DAI"
														src={getImgUrl("https://assets.metasoccer.com/icons/dai.svg", {
															height: 80
														})}
														height="16"
													/>
													<Typography variant="body2" numberOfLines={1} textAlign="center">
														Buy with DAI
													</Typography>
												</Row>
											}
											iconUrl={getImgUrl("https://assets.metasoccer.com/icons/msu.png", {
												height: 80
											})}
											name="MSU"
											onClick={() => openURLInNewTab("https://manag3r.metasoccer.com/buy-msu")}
										/>
									)}
									{catalog?.products.map((product) => (
										<BuyCatalog key={product.id} product={product} />
									))}
								</div>
							</Col>
						</Container>
					)
				}
			]}
		/>
	);
});
