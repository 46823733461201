import { squidService } from "@/modules/squid/dependencies";
import { HttpClient, MatchedTeam } from "@metasoccer/metasoccer-types";

export class AirdropService {
	constructor(private readonly httpClient: HttpClient) {}

	async getAvailableBoxes(): Promise<number> {
		try {
			const starterBoxRedeemerAddress = "0x1a063CE883a7d2d643204ADdff35C5C3e022ED0A";
			const { data } = await squidService.starterBoxByAddress(starterBoxRedeemerAddress);
			return data.starterBoxes.length;
		} catch (err) {
			return 0;
		}
	}

	async getPass(address: string): Promise<boolean> {
		try {
			const { data } = await squidService.passByAddress(address);
			return data.metaSoccerPasses.length > 0;
		} catch (err) {
			return false;
		}
	}

	async getStarterBox(address: string): Promise<boolean> {
		try {
			const { data } = await squidService.starterBoxByAddress(address);
			return data.starterBoxes.length > 0;
		} catch (err) {
			return false;
		}
	}

	async getStarterBoxClaim(address: string): Promise<boolean> {
		try {
			const { data } = await squidService.starterBoxClaimByAddress(address);
			return data.starterBoxClaims.length > 0;
		} catch (err) {
			return false;
		}
	}

	async getRanking(): Promise<{
		items: Array<{ position: number; team: MatchedTeam; value: number }>;
		lastUpdated: Date;
	}> {
		try {
			const { data } = await this.httpClient.get(`/airdrop/ranking`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getTasks(): Promise<
		Array<{ key: string; completed: boolean; claimed: boolean; data?: any; points: number }>
	> {
		try {
			const { data } = await this.httpClient.get(`/airdrop/tasks`);
			return data;
		} catch (err) {
			throw err;
		}
	}

	async getSuspiciousAddress(address: string): Promise<{ address: string; urlCases: string[]; suspicious: boolean }> {
		try {
			const { data } = await this.httpClient.get(`/airdrop/suspiciousAddress/${address}`);
			return data;
		} catch (err) {
			throw err;
		}
	}
}
